<template>
	<div>
		<el-upload class="upload-demo"
			:action="ossInfo.ossSignature.host" :show-file-list="false"
			:auto-upload="false"
			list-type="picture-card" ref="uploadImg" :on-change="handleChange" :data="fileDataObj"
			:accept="accept">
			<el-button class="umar-b10  upload-btn" type="primary">{{$i18n.t("common.ImportFile")}}</el-button>		
		</el-upload>
	</div>
</template>

<script>
	import X2JS from "x2js"; //解析xml
	export default {
		props: {
			accept:{
				type:String,
				default:".xls,.xlsx"
			},
			imgInfo:{
				type: Object,
				default: () => {
					return {}
				}
			},
			bigImgList: {
				//大图表格数据
				type: Array,
				default: () => {
					return []
				}
			},
		},
		data() {
			return {
				fileWidth:"",
				fileHeight:"",
				imageUrl:"",
				ossVideoUrl:"",
				fileDataObj:{},
				ossInfo: {
					ossHost: process.env.VUE_APP_OSS_Head,
					ossSignature: {
						host: ''
					},
					activityTranslates: [],
					langs: [],
					seoIndex: '0',
					newFiles: []
				},
			}
		},
		methods:{
			//获取OSS签名
			getOssSignature(){
			  this.$store
			    .dispatch("getSignature", {
			      dir: 'b2c'
			    })
			    .then((res) => {
			      this.ossInfo.ossSignature = res;
			      this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
			    });
			},
			handleChange(file, fileLis) {

				// console.log('fileLis',fileLis)
				if (file.size>1024 * 1024 ) {
					this.$message.error('上传失败，文件大小需＜1MB');
					this.$refs.uploadImg.clearFiles();
					return;
				}
			 
				if(file.status == 'fail') {
					this.$message.error('上传失败!');
					return
				}							
				const extension = file.name.substring(file.name.lastIndexOf('.'))
				const fileType = ['.xls','.xlsx']
				if (fileType.indexOf(extension) == -1) {
						this.$message.error('上传文件只支持xls、xlsx');
						this.$refs.uploadImg.clearFiles();
						return;
				}
				let dataObj = {
				  name: file.name,
				  key:
				    this.ossInfo.ossSignature.dir +
				    "/" +
				    new Date().getFullYear() +
				    "/" +
				    this.randomString() +
				    this.getSuffix(file.name),
				  policy: this.ossInfo.ossSignature["policy"],
				  OSSAccessKeyId: this.ossInfo.ossSignature["access_id"],
				  success_action_status: "201",
				  callback: "",
				  signature: this.ossInfo.ossSignature["signature"],
				  // file: file.file,
				};
				this.fileDataObj = dataObj;	
				setTimeout(() => {
					this.$refs.uploadImg.submit();
					var x2js = new X2JS();
					var jsonObj = x2js.xml2js(file.response);
					let ossUrl = jsonObj.PostResponse.Location;
					const formData = new FormData();
					formData.append("file",file.raw);
					formData.append("fileUrl",ossUrl);
					this.$refs.uploadImg.clearFiles();
				  	this.handlEmit(formData)
				}, 500); 					
			},
			/**
			 * 生成随机文件名
			 * @param len
			 * @returns {string}
			 */
			randomString(len) {
			  len = len || 32;
			  let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
			  let maxPos = chars.length;
			  let pwd = "";
			  for (let i = 0; i < len; i++) {
			    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
			  }
			  return pwd;
			},
			
			getSuffix(filename) {
			  let pos = filename.lastIndexOf(".");
			  let suffix = "";
			  if (pos != -1) {
			    suffix = filename.substring(pos);
			  }
			  return suffix;
			},
			handlEmit(formData){
				this.$store.dispatch('postGoodsImport', formData).then((res) => {
				    this.$message.success('导入成功');
				});			
			}
		},
		mounted() {
			this.getOssSignature()
		}
	}
</script>

<style scoped>
	.upload-demo /deep/ .el-upload--picture-card {
		width: auto;
		height: 0px;
		line-height: 12px;
		border: 0px dashed #c0ccda;
	}
</style>
