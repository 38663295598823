var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "uploadImg",
          staticClass: "upload-demo",
          attrs: {
            action: _vm.ossInfo.ossSignature.host,
            "show-file-list": false,
            "auto-upload": false,
            "list-type": "picture-card",
            "on-change": _vm.handleChange,
            data: _vm.fileDataObj,
            accept: _vm.accept
          }
        },
        [
          _c(
            "el-button",
            { staticClass: "umar-b10  upload-btn", attrs: { type: "primary" } },
            [_vm._v(_vm._s(_vm.$i18n.t("common.ImportFile")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }